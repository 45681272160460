@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Philosopher:wght@400;600;700&display=swap');

html {
  width: 100%;
  height: 100%;
  /* PRO MAX */
  max-width: 430px; 
  max-height: 932px;
  margin: auto;
  overflow: hidden;
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1);
  font-family: "Open Sans";
  overscroll-behavior: none;
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  display: flex;
  flex: 1;
  overscroll-behavior: none;
}

*:focus {
  outline: none;
}
